import React from "react";
import SupportArea from "../UI/SupportArea";

function Home(props) {

  return (
    <>
      <SupportArea />
    </>
  );
}

export default Home;
