import React from "react";
import Terms from "../UI/Terms";

function Term() {

  return (
    <Terms />
  );
}

export default Term;
